<template>
  <div id="app">
    <div id="main" class="myEchart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {

  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted(){
    this.initMyChart()
  },
  methods: {
    initMyChart() {
      let chartDom = document.getElementById("main");
      let myChart = echarts.init(chartDom);
      let option;
      let base = +new Date(1988, 9, 3);
      let oneDay = 24 * 3600 * 1000;
      let data = [[base, Math.random() * 300]];
      for (let i = 1; i < 20000; i++) {
        let now = new Date((base += oneDay));
        data.push([
          +now,
          Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
        ]);
      }
      option = {
        // 提示框组件
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        title: {
          left: "center",
          text: "用电统计",
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 20,
          },
          {
            start: 0,
            end: 20,
          },
        ],
        series: [
          {
            name: "Fake Data",
            type: "line",
            smooth: false,
            symbol: "none",
            areaStyle: {},
            data: data,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
#app{
  width: 100%;
  height: 100%;
.myEchart {
  width: 100%;
  height: 90%;
}}
</style>