<template>
  <!-- 设备监测 -->
  <div id="app">
    <div class="container">
      <div class="list">
        <div class="search">
          <el-input placeholder="输入关键字进行搜索" v-model="filterText">
          </el-input>
        </div>
        <div class="tree">
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="selectDevice"
          >
          </el-tree>
        </div>
      </div>
      <div class="content">
        <dv-border-box-4
          style="padding: 30px 0 0 30px; box-sizing: border-box"
          :color="['#135b85', '#0bced9']"
        >
          <div style="font-size: 21px; color: #1fc6a5">
            <i class="el-icon-s-opportunity"></i>
            {{ treeSelected.deviceId }}
          </div>
          <dv-decoration-1
            :color="['#067e82', '#067e82']"
            style="width: 400px; height: 20px"
          />
          <!-- 日期选择栏部分 -->
          <div>
            <el-row>
              <el-col :span="6">
                <el-date-picker
                  v-model="value1"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-col>

              <el-col :span="4"><el-button>查询</el-button></el-col>
            </el-row>
          </div>
          <!-- 设备信息 -->
          <div class="infoBox">
            <dv-border-box-7 :color="['#062c58']">
              <div class="title"><i class="el-icon-document"></i>设备信息</div>
              <dv-decoration-4
                :reverse="true"
                style="width: 250px; height: 5px"
              />
              <div class="outsideBox">
                <div class="insideBox" v-for="(item,i) in showData" :key="i">{{ item }}</div>
              </div>
            </dv-border-box-7>
          </div>
          <!-- 数据统计 -->
          <div class="dataBox">
            <dv-border-box-7 :color="['#062c58']">
              <div class="title"><i class="el-icon-document"></i>数据统计</div>
              <dv-decoration-4
                :reverse="true"
                style="width: 250px; height: 5px"
              />
              <el-row>
                <el-col :span="4">
                  <div class="itemBox">
                    <div style="color: #ccc; padding-left: 7px">总用电量</div>
                    <div class="numberBox">0.00</div>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div class="itemBox">
                    <div style="color: #ccc; padding-left: 7px">日用电量</div>
                    <div class="dataLine"> 当日用电量：(Kw/h)<span class="dataStyle">123</span></div>
                    <div class="dataLine">昨日用电量：(Kw/h)<span class="dataStyle">123</span></div>
                    <div class="dataLine">日环比：<span class="dataStyle">123</span></div>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div class="itemBox">
                    <div style="color: #ccc; padding-left: 7px">月用电量</div>
                    <div class="dataLine">当月用电量：(Kw/h)<span class="dataStyle">123</span></div>
                    <div class="dataLine">上月用电量：(Kw/h)<span class="dataStyle">123</span></div>
                    <div class="dataLine">月环比：<span class="dataStyle">123</span></div>
                  </div>
                </el-col>
              </el-row>
            </dv-border-box-7>
          </div>
          <!-- 数据分析 -->
          <div class="analyzeBox">
            <dv-border-box-7 :color="['#062c58']">
              <div class="title"><i class="el-icon-document"></i>数据分析</div>
              <dv-decoration-4
                :reverse="true"
                style="width: 250px; height: 5px"
              />
              <ElectricitySingleDataPolyline></ElectricitySingleDataPolyline>
            </dv-border-box-7>
          </div>
        </dv-border-box-4>
      </div>
    </div>
  </div>
</template>
<script>
import ElectricitySingleDataPolyline from '@/components/Electricity/parts/ElectricitySingleDataPolyline.vue'
import DeviceHistoryValue from '../../../api/manageApi/DeviceHistoryValue';
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  components: {
    ElectricitySingleDataPolyline
  },
  props: {},
  created() {
    this.getDeviceData()
  },
  data() {
    return {
      // 展示数据假数据
      showData:[
        "id:123456",
        "name:asdasdf",
        "type:asdasfd",
        "id:123456",
        "name:asdasdf",
       "type:asdasfd",
    ],
      // 搜索框双向绑定
      filterText: "",
      // 树形图绑定假数据
      treeData: [],
      // 树形图绑定假字段
      defaultProps: {
        children: "children",
        label: "deviceId",
      },
      treeSelected:{
        deviceId:'',
        deviceName:''
      },

      // 时间日期选择器绑定
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
    };
  },
  methods: {
    // tree搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.deviceId.indexOf(value) !== -1;
    },
    // 获取tree数据
    async getDeviceData(){
      let deviceType = "DXElectricMeter";
      const res = await DeviceHistoryValue.getHierarchicalList(deviceType,"")
      console.log(res.data.data.list);
      this.treeData = res.data.data.list
      // 调用默认显示的方法
      this.defaultView()
    },
    // tree的点击事件
    selectDevice(val){
      console.log(val);
      this.treeSelected.deviceId=val.deviceId
    },
    // 设置进入页面之后的默认显示事件（作为获取数据的回调函数）
    defaultView(){
      // 将首项设为默认展示
      let defaultInfo = this.treeData[0]
      this.selectDevice(defaultInfo)
    }
  },
};
</script>
  
<style lang="scss" scoped>
@font-face {
  font-family: "led regular";
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}
@import "../../../assets/css/uiCss.scss";
#app {
  .container {
    position: relative;
    width: 100%;
    height: 91vh;
    .list {
      position: absolute;
      left: 0;
      height: 100%;
      width: 15%;
      .search {
        padding-top: 15px;
      }
    }
    .content {
      position: absolute;
      right: 0;
      height: 100%;
      width: 85%;
      overflow: hidden;
      .el-button {
        color: #033744;
        background-color: #397de2;
        border: none;
      }
      .title {
        color: #1fc6a5;
        padding: 10px;
        font-size: 19px;
      }
      .outsideBox{
          padding: 10px;
        .insideBox{
          display: inline-block;
          margin: 0 10px;
          padding: 3px 7px;
          height: 30px;
          line-height: 30px;
          background-color: rgba($color: #397de2, $alpha: 0.25);
          border-radius: 4px;
          color: #1fc6a5;
         box-shadow: inset -2px -2px 2px #1c7ccf;
         cursor: default;
         letter-spacing:1.5px;
        }
      }

      .infoBox {
        height: 20%;
        width: 100%;
        margin-bottom: 7px;
      }
      .dataBox {
        height: 26%;
        width: 100%;
        margin-bottom: 7px;
        .itemBox {
          .dataLine {
            color: #1cbacf;
            width: 80%;
            height: 30px;
            margin: 15px;
            line-height: 30px;
            box-sizing: border-box;
            border-bottom: 1px dotted #1cbacf;
            .dataStyle {
              float: right;
              font-size: 30px;
              font-family: led regular;
            }
          }
          .numberBox {
            line-height: 130px;
            text-align: center;
            font-size: 60px;
            font-family: led regular;
            color: aquamarine;
          }
        }
      }
      .analyzeBox {
        height: 40%;
        width: 100%;
      }
    }
  }
}
</style>